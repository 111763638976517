<script>
export default {
  name: "YouTubeModal",
  props: {
    movie_id: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    modalClose(e) {
      if (
        e.target.className == "youtube_modal" ||
        e.target.className == "youtube_modal_close"
      ) {
        this.$emit("modalClose", false);
      }
    },
  },
  mounted() {
    console.log(this.movie_id)
  }
};
</script>

<template>
  <div class="youtube_modal" @click="modalClose($event)">
    <div class="youtube_modal_container">
      <iframe
        class="youtube_video"
        width="560"
        height="315"
        :src="'https://www.youtube.com/embed/' + movie_id"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<style scoped>
/* モーダル部分 */
.youtube_modal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
}
.youtube_modal_container {
  position: relative;
  background-color: #fff;
  width: 100vw;
  padding: 10px 40px;
}
.youtube_video {
  width: 100%;
  height: auto;
  margin: auto;
}
</style>
