<script>
import axios from 'axios'
import YouTubeModal from "@/components/YouTubeModal.vue";

export default {
  name: "Trend",
  components: {
    YouTubeModal,
  },
  data() {
    return {
      articles: {
        youtube: [],
        blog: [],
      },
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isYouTubeModalOpen: false,
      selectedMovieId: "",
    };
  },
  computed: {
    youtubeMovies() {
      return this.articles.youtube
        .slice()
        .sort((youtube_i, youtube_j) => -(youtube_i.published_time - youtube_j.published_time))
        .map((x, index) => {
        return {
          id: x.id,
          url: `https://www.youtube.com/watch?v=${x.id}`,
          title: x.title,
          concierge: x.concierge,
          publishedTime: this.dateFormat(x.published_time),
          imgUrl: `https://img.youtube.com/vi/${x.id}/maxresdefault.jpg`,
          index: index+1,
        };
      });
    },
  },
  mounted() {
    const target_url = process.env.VUE_APP_IMAGE_ROOT+"/trend-data/kazunosuke_youtube.json"
    axios.get(target_url)
      .then(res=>{
        this.articles.youtube=res.data;
      })
      .catch(e=>{
        console.log('Error',e);
      });
  },
  methods: {
    selectMovie(id) {
      this.selectedMovieId = id;
      this.isYouTubeModalOpen = true;
    },
    dateFormat(date) {
      const newdate = new Date(Number(date)*1000)
      const yyyy = String(newdate.getFullYear());
      const mm = String(newdate.getMonth() + 1).padStart(2, "0");
      const dd = String(newdate.getDate()).padStart(2, "0");
      return `${yyyy}/${mm}/${dd}`
    },
  },
};
</script>

<template>
  <main>
    <div class="trendPage_title">
      <div class="trendPage_title_container">
        <h1>{{ $t("trendTitle") }}</h1>
      </div>
    </div>
    <div class="pickup">
      <div
        v-for="movie in youtubeMovies"
        :key="movie.id"
        class="pickup_container" 
      >
        <div
          v-if="movie.index <= 4"
          class="pickup_inner clearfix"
        >
          <img
            :src="movie.imgUrl"
            class="pickup_thumnail"
            @click="selectMovie(movie.id)"
          />
          <p class="pickup_title">{{ movie.title }}</p>
          <div class="pickup_conciergeInfo">
            <img
              :src="ROOT_IMAGE_URL + '/face-icon/kazunosuke.png'"
              class="pickup_concierge"
            >
            <span class="pickup_concierge_name">
              {{ movie.concierge }}
            </span>
          </div>
          <p class="publishedTime">{{ movie.publishedTime }}</p>
        </div>
      </div>
    </div>
    <div class="trend">
      <div
        v-for="movie in youtubeMovies"
        :key="movie.id"
        class="trend_container" 
      >
        <div
          v-if="5 <= movie.index"
          class="trend_inner"
        >
          <img
            :src="movie.imgUrl"
            class="trend_thumnail"
            @click="selectMovie(movie.id)"
          />
          <div class="trend_info clearfix">
            <span class="trend_concierge_name">
              {{ movie.concierge }}
            </span>
            <p class="trend_title">{{ movie.title }}</p>
            <p class="publishedTime">{{ movie.publishedTime }}</p>
          </div>
        </div>
      </div>
    </div>
    <you-tube-modal
      :isOpen="isYouTubeModalOpen"
      :movie_id="selectedMovieId"
      @modalClose="
        this.isYouTubeModalOpen = false;
        this.selectedMovieId = '';
      "
      v-if="isYouTubeModalOpen"
    ></you-tube-modal>
    <div class="guiding_section_container">
      <div class="guiding_section_inner">
        <div class="arrow_b">
          <span></span>
        </div>
        <div class="arrow_b">
          <span></span>
        </div>
        <div class="guiding_section_toChatbot">
          <p>{{ $t("guideToItemSuggestion") }}</p>
          <router-link to="/flow/item-suggestions">
            {{ $t("guideToItemSuggestionButton") }}
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.clearfix::after{
  content: "";
  clear: both;
  display: block;
}
.trendPage_title{
  padding: 25px 20px 20px 20px;
  background-color: #f5eae7;
}
.trendPage_title h1{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.pickup{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  background-color: #f5eae7;
}
.pickup_container{
  width: 50%;
  box-sizing: border-box;
  background-color: #f5eae7;
}
.pickup_container:nth-child(1){
  padding: 5px 2.5px 2.5px 5px;
}
.pickup_container:nth-child(2){
  padding: 5px 5px 2.5px 2.5px;
}
.pickup_container:nth-child(3){
  padding: 2.5px 2.5px 5px 5px;
}
.pickup_container:nth-child(4){
  padding: 2.5px 5px 5px 2.5px;
}
.pickup_inner{
  background-color: #fff;
  padding: 10px 7.5px 2.5px 7.5px;
  border-radius: 5px;
  box-shadow: 1px 2px 16px -6px rgb(0 0 0 / 60%);
}
.pickup_thumnail{
  width: 100%;
  border-radius: 5px;
}
.pickup_title{
  margin: 7.5px 5px 7.5px 5px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  display: -webkit-box;
	-webkit-line-clamp: 3; /* 行数を指定する */
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.pickup_conciergeInfo{
  display: flex;
  align-items: center;
}
.pickup_concierge{
  width: 36px;
  height: 36px;
  margin: 0 5px;
  border-radius: 50%;
}
.pickup_concierge_name{
  font-size: 14px;
}
.publishedTime{
  float: right;
  font-size: 12px;
}
.trend_info .publishedTime{
  padding-right: 5px;
}
.trend{
  display: flex;
  flex-direction: column;
  background-color: #f5eae7;
}
.trend_inner{
  display: flex;
  align-items: center;
}
.trend_container{
  display: flex;
  align-items: center;
  background-color: #fff;
  margin: 0 5px 5px 5px;
  border-radius: 5px;
}
.trend_thumnail{
  width: 160px;
  height: 90px;
  margin: 7.5px;
  border-radius: 5px;
}
.trend_title{
  font-size: 14px;
  margin: 5px;
  display: -webkit-box;
	-webkit-line-clamp: 3; /* 行数を指定する */
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.trend_concierge_name{
  font-size: 12px;
  padding-left: 5px;
}


router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.arrow_b{
  position: relative;
  padding-bottom: 10px;

}
.arrow_b span{
  display: block;
	vertical-align: middle;
	position: absolute;
  left: 50%;
}
.arrow_b span::before, .arrow_b span::after{
  content: "";
	display: block;
	width: 30px;
  max-width: 145px;
	height: 2px;
	background-color: #777;
	position: absolute;
	right: 0;
}
.arrow_b span::before{
  transform: rotate(15deg);
  transform-origin: right center;
  top: 33%;
}
.arrow_b span::after{
  transform: rotate(165deg);
  transform-origin: right center;
  top: -33%;
  right: 1px;
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #f5eae7;
}
.guiding_arrow {
  width: 26px;
  padding-bottom: 40px;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444;
  background-color: #fadad2;
  font-weight: bold;
  box-shadow: 1px 2px 16px -6px rgb(0 0 0 / 60%);
}
.guiding_section_toChatbot {
  padding: 50px 0;
}
.guiding_section_toChatbot p {
  display: block;
  padding-bottom: 20px;
  font-size: 16px;
}
</style>
